<template>
  <div class="mx-a mb-16">
    <BasicInfo />
    <BusinessInfo class="mt-1" />
    <BankInfo class="mt-1" />
  </div>
</template>

<script>
import BasicInfo from "@/components/ui/myInfo/BasicInfo";
import BusinessInfo from "@/components/ui/myInfo/BusinessInfo";
import BankInfo from "@/components/ui/myInfo/BankInfo";

export default {
  name: "Company",
  mixins: [],
  components: { BasicInfo, BusinessInfo, BankInfo },

  /*** data ***/
  data() {
    return {
      basicData: {},
      businessData: {},
      bankData: {},
    };
  },

  /*** created ***/
  created() {
    this.ntDt = this._.cloneDeep(this.$data);
  },

  /*** mounted ***/
  async mounted() {},

  //   beforeRouteLeave(to, from, next) {
  //     this.toRoute.path = to.fullPath;
  //     this.toRoute.meta = to.meta.id;
  //     if (this.isReset) {
  //       next();
  //     } else {
  //       next(false);
  //       if (!this.isReset) {
  //         this.onPageReset();
  //       }
  //     }
  //   },

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {},
};
</script>

<style scoped lang="scss">
</style>
